import { Translation } from 'shared/components/translation/translation.component';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DevicesIcon from '@mui/icons-material/Devices';

import './web-exclusive-tag.component.scss';

interface Props {
  isWebExclusive: boolean;
}

export const WebExclusiveTag = ({ isWebExclusive }: Props) => {
  const { findLoadsWebExclusiveWeb } = useFlags();

  if (!findLoadsWebExclusiveWeb || !isWebExclusive) {
    return;
  }

  return <div className="web-exclusive-tag" data-testid="web-exclusive-tag">
    <DevicesIcon style={{marginRight: "2px", verticalAlign: "middle", color: "#005C05", height: "16px" }} />
    <span><Translation resource='CARRIER_EXCLUSIVE' /></span>
  </div>;
};
