import { lazy } from 'react';
import { breakpoints, useWindowSizeBreakpoints } from 'app/hooks/use-window-size-breakpoints.hook';

const LoadDetailsModalComponent = lazy(() => import('pages/find-loads-ver2/modals/load-details-modal.component'));
const ReloadDetailsModal = lazy(() => import('pages/find-loads-ver2/modals/reload-details-modal.component'));
const ExitWithoutBookingModal = lazy(() => import('pages/find-loads-ver2/offers/modals/exit-without-booking-modal.component'));
const OfferDiscardModal = lazy(() => import('pages/find-loads-ver2/offers/modals/offer-discard-modal.component'));
const OfferModal = lazy(() => import('pages/find-loads-ver2/offers/modals/offer-modal.component'));
const LoadInsuranceExpiredModal = lazy(() => import('shared/components/modal/load-insurance-expired.component'));
const ViewAllReloadsModal = lazy(() => import('shared/reloads/modals/reloads-view-all-modal.component'));
const ExitWithoutResponseModal = lazy(() => import('pages/find-loads-ver2/offers/modals/exit-without-response-modal.component'));
const LoadNotificationModal = lazy(() => import('@shared/components/load-notifications/load-notification-modal.component'));

type Props = {
  sendLDEvents: boolean
}
export const FindLoadsLazyModals = ({ sendLDEvents }: Props) => {
  const isFullScreenModalBreakpoint = useWindowSizeBreakpoints(null, breakpoints.sm);
  const isWebBreakpoint = useWindowSizeBreakpoints(breakpoints.lg_two_columns_cuttoff);

  return <>
    <LoadDetailsModalComponent
      isWebView={isWebBreakpoint}
      isFullScreenModalBreakpoint={isFullScreenModalBreakpoint}
      sendLDEvents={sendLDEvents}
    />
    <ViewAllReloadsModal />
    <ReloadDetailsModal sendLDEvents={sendLDEvents} />
    <OfferModal />
    <OfferDiscardModal />
    <ExitWithoutResponseModal />
    <ExitWithoutBookingModal />
    <LoadInsuranceExpiredModal />
    <LoadNotificationModal />
  </>;
};