import { KeyboardEvent } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Pill } from 'shared/components/pill/pill.component';
import { CapLabel } from 'shared/components/cap-restricted/cap-label.component';
import { AvailableLoadSummary, SearchCategory } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { LoadActions } from 'shared/loads/load-actions.component';
import { LoadCard } from 'shared/loads/load-card.component';
import { WebExclusiveTag } from 'shared/components/web-exclusive/web-exclusive-tag.component';
import './find-loads-result.component.scss';

export interface FindLoadsResultProps {
  load: AvailableLoadSummary;
  showDestinationDeadhead: boolean;
  showOriginDeadhead: boolean;
  isSelected?: boolean;
  selectLoad: (load: AvailableLoadSummary) => void;
  setLoadResultRef: (ref) => void;
  isMetric: boolean;
}

export const FindLoadsResult = (props: FindLoadsResultProps) => {
  const {
    load,
    showDestinationDeadhead,
    showOriginDeadhead,
    isSelected,
    selectLoad,
    setLoadResultRef,
    isMetric
  } = props;

  const { findLoadsSearchCategorization } = useFlags();
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {

    if (event.key === 'Enter') {
      selectLoad(load);
    }
  }

  const handleCategories = (category: string) => {
    if (isMetric && category === 'BestRatePerMile') {
      return 'BESTRATEPERKM' as keyof IResources
    }
    return category.toUpperCase() as keyof IResources
  }

  return (
    <div
      data-testid="find-loads-result"
      className={`find-loads-result${isSelected ? ' selected' : ''}`}
      onClick={() => selectLoad(load)}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      ref={setLoadResultRef}
    >
      <div className="js-load-number hidden">{load.number}</div>
      <div className="find-loads-result-section find-loads-result-pills-area">
        <CapLabel loadTier={load.carrierTier} />
        {!!(load.tags?.length || (findLoadsSearchCategorization && !!load.categories?.length)) && 
          <div className="find-loads-result-pills" data-testid="find-loads-result-pills">
            {load.categories?.map((c: SearchCategory) => <Pill key={c} resource={handleCategories(c)} />)}
            {load.tags?.map((t: any) => <Pill key={t} resource={t.toUpperCase()} />)}
          </div>
        }
        <WebExclusiveTag isWebExclusive={load.isWebExclusive} />
      </div>
      <LoadCard
        load={load}
        showDestinationDeadhead={showDestinationDeadhead}
        showOriginDeadhead={showOriginDeadhead}
        sectionName="find-loads-result"
      />
      <div className="find-loads-result-section find-loads-result-actions">
        <LoadActions load={load} sectionName="find-loads-result" />
      </div>
    </div>
  );
};
