import classNames from 'classnames';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { LocationRadius } from 'shared/find-loads/components/location-radius.component';
import { useUpdateOnFormChanges } from 'app/hooks/forms/use-update-on-form-changes.hooks';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { User } from 'shared/models/user.model';
import { useFlags } from 'launchdarkly-react-client-sdk';

import './find-loads-area-select.component.scss';

interface Props {
  formGroup: NavCarrierFormGroup;
  labelKey: 'ORIGIN' | 'DESTINATION';
  onLocationChange: (location: LocationSelectGrouping) => any;
  showRadius?: boolean;
  idPrefix?: string;
}

export const FindLoadsAreaSelect = ({ formGroup, labelKey, showRadius = true, idPrefix, onLocationChange }: Props) => {
  const { findLoadsNewAutocomplete } = useFlags();

  useUpdateOnFormChanges(formGroup);

  const user = useSelector<User>(state => state.auth?.user);

  const classes = classNames(`find-loads-area-select location-select location-select-${idPrefix ? idPrefix + '-' + labelKey : labelKey}`, {
    'form-group has-error': formGroup.hasErrors(),
  });

  return (
    <div className={classes} data-testid="find-loads-area-select">
      <LocationRadius
        label={labelKey}
        value={formGroup.value as any}
        showRadius={showRadius}
        id={idPrefix ? idPrefix + '-' + labelKey : null}
        isMetric={user.isMetric()}
        onLocationChange={onLocationChange}
        useV2Autocomplete={findLoadsNewAutocomplete}
      />
    </div>
  );
};