import { ChangeEvent, FormEvent, useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment';
import 'rxjs/add/observable/forkJoin';
import { resetAvailableLoads, resetErrorMessage, searchModified, getSuggestedLoads, setSearchCorrelationId } from 'shared/find-loads/redux/find-loads.actions';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { FindLoadsSearchForm } from './find-loads-search.form';
import { convertDistanceToMetric } from 'shared/components/formatters/unit-conversion.formatter';
import { getCountryNameBasedOnCode } from '../../find-loads/shared/country.transformer';
import { DateRangePickerV2Value } from 'shared/components/date-picker/date-range-picker/date-range-picker-v2.component';
import { FindLoadsFiltersModal } from '../filters/find-loads-filters-modal.component';
import { FindLoadsFiltersForm } from '../filters/find-loads-filters.form';
import { defaultSearchCriteria } from '../../find-loads/available-loads-search.criteria';
import { LoadNotFoundBanner } from './load-not-found-banner.component';
import { EquipmentTypeValues } from 'shared/find-loads/components/equipment-select.component';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { setSubmitSearchEvent } from 'features/analytics/redux/analytics-v2.actions';
import { CaptchaStatus } from 'shared/components/turnstile-captcha/types';
import { UUID } from 'angular2-uuid';
import { fetchSearchHistoryLocations } from '../../find-loads/search-history/search-history.actions';
import { publishSearchSubmittedEvent } from 'app/repositories/analytics.repository';
import { setPage } from 'shared/components/pagination/pagination.reducer';
import { ConvertToSearchCriteria } from 'pages/find-loads-ver2/search/find-loads-search-functions';
import { FindLoadsSearchFormComponent } from 'pages/find-loads-ver2/search/find-loads-search-form.component';
import { useDispatch } from 'react-redux';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import isEqual from 'lodash.isequal';

import './search-area.component.scss';

const convertDistance = (value: number) => value && Math.round(convertDistanceToMetric(value));

interface OwnProps {
  onSearch: (criteria: AvailableLoadSearchCriteriaJSON, saveAsPreferredLane?: boolean) => any;
  onLoadNumberSearch: (criteria: AvailableLoadSearchCriteriaJSON, saveAsPreferredLane?: boolean) => any;
  onReset: () => any;
  abTesting: boolean;
  useCaptcha: boolean;
  captchaStatus: CaptchaStatus;
  sendAnalytics: boolean;
}

export const SearchArea = ({onSearch, onLoadNumberSearch, onReset, abTesting, useCaptcha, captchaStatus, sendAnalytics }: OwnProps) => {

	const dispatch = useDispatch();
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	// store selectors
	const { searchCriteria, results, user } = useSelector(s => ({
		searchCriteria: s.findLoads.searchCriteria || null,
		results: s.findLoads.results,
		user: s.auth.user,
	}));

	const isMetric = user.isMetric();
	const captchaSuccessful = useCaptcha ? captchaStatus === CaptchaStatus.SUCCESS : true;
	const noLoads = !results?.loads;

	// component level state
	const [isLoadNumSearch, setIsLoadNumSearch] = useState(Boolean(searchCriteria?.loadnumber));
	const [submitPerformed, setSubmitPerformed] = useState(false);
	const prevSearchCriteriaRef = useRef(null);

	// forms
	const [filtersForm, updateFiltersForm] = useState(new FindLoadsFiltersForm());
	const [form, updateSearchForm] = useState(new FindLoadsSearchForm(isMetric));

	/**
	*
	* @param isLoadNumSearch the search is by load number
	*/
	const handleSelection = (_isLoadNumSearch: boolean) => {
		if (_isLoadNumSearch !== isLoadNumSearch) {
			clearForm();
			setIsLoadNumSearch(_isLoadNumSearch);
		}
	}

	const setToODSearch = () => handleSelection(false);

	// first render
	useEffect(() => {
		form.changes.subscribe(() => forceUpdate());
		form.valueChanges.subscribe(() => submitPerformedDone());
		dispatch(fetchSearchHistoryLocations());
	}, []);

	useEffect(() => {
		form.changes.subscribe(() => forceUpdate());
		form.valueChanges.subscribe(() => submitPerformedDone());
		
		if (searchCriteria) {
			setFromCriteria(searchCriteria);
			dispatch(setSubmitSearchEvent({ searchCriteria, searchCorrelationId: null, isButtonClick: false }));
		}
		if (!searchCriteria && noLoads && captchaSuccessful && !submitPerformed) {
			dispatch(getSuggestedLoads());
		}
		if (isEqual(prevSearchCriteriaRef.current, searchCriteria)) {
			return;
		}
		prevSearchCriteriaRef.current = searchCriteria;
		if (searchCriteria && noLoads && captchaSuccessful) {
			performSearch();
		}
		if (!searchCriteria) {
			form.markAsPristine();
		}
	}, [searchCriteria, isLoadNumSearch]);

	const setFromCriteria = (criteria: AvailableLoadSearchCriteriaJSON) => {
		setIsLoadNumSearch(!!criteria.loadNumber);
		const defaultRadius = 100;
		const { originCountryCode, originCountryName, originStateProvinceCode, originCity, originRadiusMiles, originLatitude, originLongitude } = criteria;
		const { destinationCountryCode, destinationCountryName, destinationStateProvinceCode, destinationCity, destinationRadiusMiles, destinationLatitude, destinationLongitude } = criteria;

		const originCountry = originCountryName ? originCountryName : getCountryNameBasedOnCode(originCountryCode);
		const destinationCountry = destinationCountryName ? destinationCountryName : getCountryNameBasedOnCode(destinationCountryCode);
		let originPlace = {
			displayName: originCity + ', ' + originStateProvinceCode + ', ' + originCountry,
			cityName: originCity, countryName: originCountryName,
			stateProvinceCode: originStateProvinceCode,
			countryCode: originCountryCode,
			coordinate: { latitude: originLatitude, longitude: originLongitude }
		};
		let destinationPlace = {
			displayName: destinationCity + ', ' + destinationStateProvinceCode + ', ' + destinationCountry,
			cityName: destinationCity,
			countryName: destinationCountryName,
			stateProvinceCode: destinationStateProvinceCode,
			countryCode: destinationCountryCode,
			coordinate: { latitude: destinationLatitude, longitude: destinationLongitude }
		};
		form.reset();
		form.disable(true);

		form.get('origin.country').setValue(originCountryCode);
		form.get('origin.stateProv').setValue(originStateProvinceCode);
		form.get('origin.city').setValue(originCity);
		if (originCity && originStateProvinceCode) {
		  form.get('origin.place').setValue(originPlace);
		} else if (originCity) {
			originPlace = {
				displayName: originCity + ', ' + originCountry,
				cityName: originCity,
				countryName: originCountryName,
				stateProvinceCode: originStateProvinceCode,
				countryCode: originCountryCode,
				coordinate: { latitude: originLatitude, longitude: originLongitude }
			};
			form.get('origin.place').setValue(originPlace);
		}
		form.get('origin.radius').setValue((isMetric ? convertDistance(originRadiusMiles) : originRadiusMiles) || defaultRadius);
		form.get('destination.country').setValue(destinationCountryCode);
		form.get('destination.stateProv').setValue(destinationStateProvinceCode);
		form.get('destination.city').setValue(destinationCity);
		if (destinationCity && destinationStateProvinceCode) {
			form.get('destination.place').setValue(destinationPlace);
		} else if (destinationCity) {
			destinationPlace = {
				displayName: destinationCity + ', ' + destinationCountry,
				cityName: destinationCity,
				countryName: destinationCountryName,
				stateProvinceCode: destinationStateProvinceCode,
				countryCode: destinationCountryCode,
				coordinate: { latitude: destinationLatitude, longitude: destinationLongitude }
			};
			form.get('destination.place').setValue(destinationPlace);
		}
		form.get('destination.radius').setValue((isMetric ? convertDistance(destinationRadiusMiles) : destinationRadiusMiles) || defaultRadius);
		form.get('dateRange.start').setValue(criteria.pickupStart ? moment(criteria.pickupStart) : null);
		form.get('dateRange.end').setValue(criteria.pickupEnd ? moment(criteria.pickupEnd) : null);
		form.get('equipment.type').setValue(criteria.mode || EquipmentType.All);
		form.get('equipment.extendedType').setValue(criteria.specializedEquipmentCode || EquipmentType.All);
		form.get('loadNumber').setValue(criteria.loadNumber || null);

		form.enable(true);
		form.markAsPristine();
	}

	const onFiltersApplied = (filtersForm: FindLoadsFiltersForm) => {
		updateFiltersForm(filtersForm);
		performSearch(filtersForm);
	}

	/**
	* A value has changed since the last submit was performed
	*/
	const submitPerformedDone = () => {
		setSubmitPerformed(false);
	}

	const submitForm = (e: FormEvent<HTMLFormElement>) => {
		e?.preventDefault();
		setSubmitPerformed(true);
		performSearch();
	};

	const performSearch = (filters?: FindLoadsFiltersForm) => {
		if (!form.valid) {
			return form.touch();
		}

		dispatch(resetAvailableLoads());
		dispatch(resetErrorMessage());

		const shipmentNumber = form.get('loadNumber').value;

		if (shipmentNumber) {
			const searchCriteria = ConvertToSearchCriteria(form, isMetric);
			const searchCorrelationId = UUID.UUID();
			onLoadNumberSearch(searchCriteria, false);
			dispatch(setSubmitSearchEvent({ searchCriteria, searchCorrelationId, isButtonClick: false }));
			dispatch(setSearchCorrelationId(searchCorrelationId));
			if (sendAnalytics) {
				publishSearchSubmittedEvent('load', searchCorrelationId, searchCriteria, user);
			} 
			return;
		}

		const originForm = form.get('origin') as NavCarrierFormGroup;
		const destinationForm = form.get('destination') as NavCarrierFormGroup;

		// should not have errors origin or destination
		if (originForm.hasErrors() || destinationForm.hasErrors()) {
			return;
		}

		const searchFilters = filters || filtersForm
	
		const fullSearchCriteria = {
			...defaultSearchCriteria,
			...ConvertToSearchCriteria(form, isMetric, abTesting),
			...searchFilters?.toSearchCriteria(isMetric)
		};
		onSearch(fullSearchCriteria, false);

		const searchCorrelationId = UUID.UUID();

		dispatch(setSubmitSearchEvent({ searchCriteria: fullSearchCriteria, searchCorrelationId, isButtonClick: true }));
		dispatch(setSearchCorrelationId(searchCorrelationId));
		if (sendAnalytics) {
			publishSearchSubmittedEvent('origin-destination', searchCorrelationId, fullSearchCriteria, user);
		}
		dispatch(setPage('find-loads-results', 1));
	}

	const clearForm = () => {
		form.reset();
		if (searchCriteria) {
			filtersForm?.reset();
			dispatch(searchModified());
			onReset(); // clears URL
		}
	};

	const resetForm = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		clearForm();
	};

	const setLocation = (prefix: string, location: LocationSelectGrouping) => {
		form.get(prefix + '.country').setValue(location.country);
		form.get(prefix + '.stateProv').setValue(location.stateProv);
		form.get(prefix + '.city').setValue(location.city);
		form.get(prefix + '.place').setValue(location.place);
		form.get(prefix + '.radius').setValue(location.radius);

		// don't trigger search modified since partial locations are not sent to the API on search
		// search criteria are effectively equal until the city has been set on a location
		if (location.place) {
			dispatch(searchModified());
		}
	};

	const setOriginLocation = (location: LocationSelectGrouping) => {
		setLocation('origin', location);
	};

	const setDestinationLocation = (location: LocationSelectGrouping) => {
		setLocation('destination', location);
	};

	const setLoadNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const numericValue = parseNumberInput(e.target.value);
		form.get('loadNumber').setValue(numericValue);
		dispatch(searchModified());
	}

	const setDateRange = (value: DateRangePickerV2Value) => {
		// We always want to set 00:00:00-23:59:59 for date times for the pick start/end search criteria.
		const start = value.startDate ? value.startDate.startOf('day') : null;
		const end = value.endDate ? value.endDate.endOf('day') : null;
		form.get('dateRange').setValue({ start, end });
		dispatch(searchModified());
	};

	const setEquipmentTypes = (values: EquipmentTypeValues) => {
		const { type, extendedType } = values;
		const equipmentTypeField = form.get('equipment.type');
		const extendedTypeField = form.get('equipment.extendedType');

		if (type !== equipmentTypeField.value || extendedType !== extendedTypeField.value) {
			dispatch(searchModified());
			if (type !== equipmentTypeField.value) {
				equipmentTypeField.setValue(type);
			}
			if (extendedType !== extendedTypeField.value) {
				extendedTypeField.setValue(extendedType);
			}
		}
	};

	const parseNumberInput = (value: string) => {
		const strippedInput = value.replace(/[^\d]/g, '');
		return (strippedInput !== '' ? Number(strippedInput) : strippedInput);
	}

	const loadNumSearchPerformed = (searchCriteria && searchCriteria.loadNumber);
	const disableSubmission = useCaptcha && !(captchaStatus === CaptchaStatus.SUCCESS);

	return (
		<div className="single-search search-component">
		{loadNumSearchPerformed &&
			<LoadNotFoundBanner
				results={results}
				changeSearchSelection={setToODSearch}
			/>
		}
		<FindLoadsSearchFormComponent 
			form={form}
			isLoadNumSearch={isLoadNumSearch}
			submitPerformed={submitPerformed}
			disableSubmission={disableSubmission}
			handleSelection={handleSelection}
			setOriginLocation={setOriginLocation}
			setDestinationLocation={setDestinationLocation}
			setDateRange={setDateRange}
			setEquipmentTypes={setEquipmentTypes}
			setLoadNumber={setLoadNumber}
			submitForm={submitForm}
			resetForm={resetForm}
		/>
		<FindLoadsFiltersModal onFiltersApplied={onFiltersApplied} isMetric={isMetric} searchCriteria={searchCriteria}/>
		</div>
	);
}
